<div>
  <form *ngIf="!submitted" id="contactForm" class="needs-validation" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="First Name" formControlName="firstName" 
          [ngClass]="{'is-invalid': form.touched && f['firstName'].invalid}">
          <div *ngIf="f['firstName'].errors" class="invalid-feedback">
            <div *ngIf="f['firstName'].errors['required']">Name is required.</div>
          </div>
        </div>
      </div>
      
      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Last Name" formControlName="lastName">
        </div>
      </div>
      
      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <input type="email" class="form-control" placeholder="Email" formControlName="email"
          [ngClass]="{'is-invalid': form.touched && f['email'].invalid}">
          <div *ngIf="f['email'].errors" class="invalid-feedback">
            <div *ngIf="f['email'].errors['required']">Email is required.</div>
            <div *ngIf="f['email'].errors['email']">Invalid email.</div>
          </div>
        </div>
      </div>
      
      <div class="col-lg-12 col-md-6">
        <div class="form-group">
          <input type="phone" class="form-control" placeholder="Phone" formControlName="phone">
        </div>
      </div>
      
      <div class="col-lg-12 col-md-6">
        <div class="form-group">
          <input type="text" class="form-control" placeholder="Organization" formControlName="organization">
        </div>
      </div>
      
      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <textarea class="form-control" id="message" cols="30" rows="5" placeholder="Your Message" formControlName="message"></textarea>
        </div>
      </div>
      
      <div class="col-lg-12 col-md-12">
        <button type="submit" class="btn btn-primary">Send</button>
      </div>
    </div>
  </form>
  <div *ngIf="submitted">
    <div class="text-center"><h3>We will get in touch with you soon!</h3></div>
  </div>
</div>